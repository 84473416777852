import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControl, IconButton, InputAdornment, MenuItem, Select, Typography } from '@material-ui/core';
import {
  EmailOutlined as EmailIcon,
  ExpandMore as ExpandMoreIcon,
  LocalHospital as HospitalIcon,
  LocationOn as LocationOnIcon,
  PeopleOutlined as PeopleIcon,
  PhoneOutlined as PhoneIcon,
} from '@material-ui/icons';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ErrorIconOutlined from '@material-ui/icons/ErrorOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import clsx from 'clsx';
import { Button, CheckBox, Input } from 'components/atoms';
import { BRAND_NAME, ENUM_SCOPE } from 'constants/Enums';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { Controller, useForm } from 'react-hook-form';
import AddressService from 'services/AddressService';
import { ENV_COUNTRY } from 'sysconfig';
import { NotifyUtils, ValidateUtils, regUtils } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import useMobileV2 from 'zustand-lib/storeMobile';
import styles from './styles.module.css';

const { validateData, Error, isEmpty } = ValidateUtils;

// update to zustand for country
const { MAP_PHONE_REGEX, EMAIL_REGEX } = regUtils;

const LIST_PROVINCE_CODE_NEED_FILL_DISTRICT_WARD = ['10'];

const validateSignUp = ({ isCheckAgree, name, email, password, phone }, failCallback, translatePopupAction) => {
  try {
    validateData.name(name);
    validateData.phoneNumber(phone);
    if (!isEmpty(email)) {
      validateData.email(email);
    }
    validateData.password(password);
    if (!isCheckAgree) throw new Error('message_agree_terms');
    return true;
  } catch (error) {
    NotifyUtils.error(translatePopupAction(`popup_register.${error?.message}`) || translatePopupAction('popup_register.message_error'));
    failCallback(error);
    return false;
  }
};

const NewSignUpForm = React.memo((props) => {
  const { t: translatePopupAction } = useTranslation('popup-action');
  const { t: translateCommon } = useTranslation('common');
  const [showPassword, setShowPassword] = useState(false);
  const { className, onClickSignIn, onClickSignUp, referCode, page, setPage, handleScrollTop } = props;
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const beta = useMobileV2((state) => state.beta);
  const router = useRouter();

  const schema = yup
    .object({
      name: yup
        .string()
        .required(translatePopupAction('popup_register.validate_require_username'))
        .min(2, translatePopupAction('popup_register.validate_username_min_2_word')),
      password: yup.string().required(translatePopupAction('popup_register.validate_password_empty')),
      phone: yup
        .string()
        .required(translatePopupAction('popup_register.not_entered_your_phoneNumber'))
        .matches(MAP_PHONE_REGEX[ENV_COUNTRY] || MAP_PHONE_REGEX.en, translatePopupAction('popup_register.phone_not_correct_format')),
      email: yup.string().matches(EMAIL_REGEX, translatePopupAction('popup_register.email_not_correct_format')),
    })
    .required();
  const {
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      password: '',
      scope: 'PHARMACY',
      isCheckAgree: false,
    },
  });

  useEffect(() => {
    const getListProvince = async () => {
      try {
        const provinceResult = await AddressService.getProvinces();
        setProvinces(provinceResult);
      } catch (error) {
        NotifyUtils.error('Can not get list province');
      }
    };

    getListProvince();
  }, []);

  useEffect(() => {
    if (LIST_PROVINCE_CODE_NEED_FILL_DISTRICT_WARD.includes(watch('provinceCode'))) {
      const getListDistrict = async () => {
        try {
          const districtResult = await AddressService.getDistrictsByProvince(watch('provinceCode'));
          setDistricts(districtResult);
          setValue('districtCode', '');
          setValue('wardCode', '');
        } catch (error) {
          NotifyUtils.error('Can not get list district');
        }
      };
      getListDistrict();
    }
  }, [watch('provinceCode')]);

  useEffect(() => {
    if (watch('districtCode')) {
      console.log('call get ward');
      const getListWard = async () => {
        try {
          const wardResult = await AddressService.getWardsByDistrict(watch('districtCode'));
          setWards(wardResult);
          setValue('wardCode', '');
        } catch (error) {
          NotifyUtils.error('Can not get list ward');
        }
      };
      getListWard();
    }
  }, [watch('districtCode')]);

  const handleSubmitSignUp = useCallback(
    (data) => {
      // validate
      if (
        validateSignUp(
          data,
          (error) => {
            if (error) {
              const newError = {};
              newError[error.type] = true;
            }
          },
          translatePopupAction,
        )
      ) {
        onClickSignUp(data);
      }
    },
    [onClickSignUp],
  );

  const handleClickSignIn = useCallback(
    (e) => {
      e.preventDefault();
      onClickSignIn();
    },
    [onClickSignIn],
  );

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickTerms = useCallback(
    (e) => {
      e.preventDefault();
      router.push('/?terms=true');
      setPage(page + 2);
      handleScrollTop();
    },
    [page],
  );

  const IconAccount = (
    <InputAdornment position="start" className={styles.input_icon}>
      <AccountCircleOutlinedIcon />
    </InputAdornment>
  );
  const IconPassword = (
    <InputAdornment position="start" className={styles.input_icon}>
      <LockOutlinedIcon />
    </InputAdornment>
  );

  const IconPhone = (
    <InputAdornment position="start" className={styles.input_icon}>
      <PhoneIcon />
    </InputAdornment>
  );

  const IconLocation = (
    <InputAdornment position="start" className={styles.input_icon}>
      <LocationOnIcon />
    </InputAdornment>
  );
  const IconEndPassword = (
    <InputAdornment position="end" className={styles.input_icon}>
      <IconButton onClick={handleClickShowPassword}>{!showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}</IconButton>
    </InputAdornment>
  );
  const IconInviter = (
    <InputAdornment position="start" className={styles.input_icon}>
      <PeopleIcon />
    </InputAdornment>
  );
  const IconEmail = (
    <InputAdornment position="start" className={styles.input_icon}>
      <EmailIcon />
    </InputAdornment>
  );
  const IconHospital = (
    <InputAdornment position="start" className={styles.input_icon}>
      <HospitalIcon />
    </InputAdornment>
  );

  const labelAgree = (
    <div>
      {translatePopupAction('popup_register.agree_policy')}&nbsp;
      {beta && isMobile ? (
        <Box component="a" onClick={handleClickTerms} style={{ color: '#09884D' }}>
          {translatePopupAction('popup_register.policy')}
        </Box>
      ) : (
        <a href="/terms-and-condition" target="_blank" rel="noreferrer" style={{ color: '#09884D' }}>
          {translatePopupAction('popup_register.policy')}
        </a>
      )}
      {/* <a href="/terms-and-condition" target="_blank" rel="noreferrer" style={{ color: '#09884D' }}>
        Điều khoản sử dụng*
      </a> */}
    </div>
  );

  return (
    <div className={clsx(className, styles.form_sign_up)}>
      {beta && isMobile && (
        <div className={styles.wrapperTitle} style={{ marginTop: '60px' }}>
          <p className="title">{translatePopupAction('popup_register.title')}</p>
        </div>
      )}
      <form className={className} onSubmit={handleSubmit(handleSubmitSignUp)}>
        <Controller
          name="name"
          control={control}
          className={styles.form_control}
          render={({ field }) => (
            <FormControl fullWidth>
              <Input
                startAdornment={IconAccount}
                placeholder={translatePopupAction('popup_register.username')}
                variant="outlined"
                error={!!errors.name || false}
                className={styles.input}
                data-test="signup-username"
                {...field}
              />
              {errors.name?.message && (
                <div className={styles.text_error} data-test="signup-err-username">
                  <ErrorIconOutlined /> {errors.name?.message}
                </div>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <Input
                startAdornment={IconPhone}
                placeholder={translatePopupAction('popup_register.phone_number')}
                variant="outlined"
                error={!!errors.phone || false}
                className={styles.input}
                data-test="signup-phone"
                {...field}
              />
              {errors.phone?.message && (
                <div className={styles.text_error} data-test="signup-err-phone">
                  <ErrorIconOutlined /> {errors.phone?.message}
                </div>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <Input
                startAdornment={IconEmail}
                placeholder={translatePopupAction('popup_register.email')}
                variant="outlined"
                error={!!errors.email || false}
                className={styles.input}
                data-test="signup-email"
                {...field}
              />
              {errors.email?.message && (
                <div className={styles.text_error} data-test="signup-err-email">
                  <ErrorIconOutlined /> {errors.email?.message}
                </div>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <Input
                type={showPassword ? 'text' : 'password'}
                autoComplete="password"
                startAdornment={IconPassword}
                endAdornment={IconEndPassword}
                placeholder={translatePopupAction('popup_register.password')}
                variant="outlined"
                error={!!errors.password || false}
                className={styles.input}
                data-test="signup-password"
                {...field}
              />
              {errors.password?.message && (
                <div className={styles.text_error} data-test="signup-err-password">
                  <ErrorIconOutlined /> {errors.password?.message}
                </div>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="provinceCode"
          control={control}
          render={({ field }) => (
            <FormControl margin="none" fullWidth>
              <Select
                key={uuidv4()}
                className={styles.container_select}
                name="provinceCode"
                variant="outlined"
                IconComponent={ExpandMoreIcon}
                input={<Input data-test="signup-provinceCode" startAdornment={IconLocation} />}
                {...field}
              >
                {provinces.map((province) => (
                  <MenuItem value={province.value} key={uuidv4()}>
                    {province.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        {LIST_PROVINCE_CODE_NEED_FILL_DISTRICT_WARD.includes(watch('provinceCode')) && (
          <>
            <Controller
              name="districtCode"
              control={control}
              render={({ field }) => (
                <FormControl margin="none" fullWidth>
                  <Select
                    key={uuidv4()}
                    className={styles.container_select}
                    name="districtCode"
                    variant="outlined"
                    IconComponent={ExpandMoreIcon}
                    input={<Input data-test="signup-districtCode" startAdornment={IconLocation} />}
                    {...field}
                  >
                    {districts.map((province) => (
                      <MenuItem value={province.value} key={uuidv4()}>
                        {province.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {watch('districtCode') && (
              <Controller
                name="wardCode"
                control={control}
                render={({ field }) => (
                  <FormControl margin="none" fullWidth>
                    <Select
                      key={uuidv4()}
                      className={styles.container_select}
                      name="wardCode"
                      variant="outlined"
                      IconComponent={ExpandMoreIcon}
                      input={<Input data-test="signup-wardCode" startAdornment={IconLocation} />}
                      {...field}
                    >
                      {wards.map((ward) => (
                        <MenuItem value={ward.value} key={uuidv4()}>
                          {ward.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            )}
          </>
        )}

        {/* todo: */}
        <Controller
          name="referCode"
          defaultValue={referCode}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <Input
                startAdornment={IconInviter}
                value={referCode}
                placeholder={translatePopupAction('popup_register.referral_number')}
                variant="outlined"
                // disabled={!!referCode}
                className={styles.input}
                data-test="signup-referCode"
                {...field}
              />
            </FormControl>
          )}
        />

        <Controller
          name="scope"
          control={control}
          defaultValue={ENUM_SCOPE.PHARMACY}
          render={({ field }) => (
            <FormControl fullWidth>
              <Select
                className={styles.container_select}
                name="scope"
                variant="outlined"
                defaultValue={ENUM_SCOPE.PHARMACY}
                onChange={(e) => {
                  // eslint-disable-next-line no-underscore-dangle
                  control._updateFieldArray('scope', e.target.value);
                }}
                IconComponent={ExpandMoreIcon}
                input={<Input startAdornment={IconHospital} />}
                {...field}
              >
                {Object.keys(ENUM_SCOPE).map((item) => (
                  <MenuItem key={item} value={item}>
                    {translateCommon(`scope.${item}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Typography className={styles.disclaimer}>
          {translatePopupAction('popup_register.message_confirm_signup_account').replace('{BRAND_NAME}', BRAND_NAME)}
        </Typography>
        <Controller
          name="isCheckAgree"
          defaultValue={false}
          control={control}
          render={({ field }) => (
            <div className={styles.agree_term}>
              <CheckBox data-test="signup-isCheckAgree" label={labelAgree} {...field} />
            </div>
          )}
        />
        {isDesktop && (
          <div className={`${styles.link_login_wrapper} ${styles.center}`}>
            <p>
              {translatePopupAction('popup_register.have_account')}&nbsp;
              <a href="#top" className={styles.link_login} onClick={handleClickSignIn}>
                {translatePopupAction('popup_register.login')}
              </a>
            </p>
          </div>
        )}

        <Box className={styles.center}>
          <Button className={styles.btn_register} type="submit" data-test="signup-submit">
            {translatePopupAction('popup_register.create_account')}
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default NewSignUpForm;
